import { Box, Grid } from '@material-ui/core'
import { useMemo } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import styles from './MultiStepSignUp.module.scss'

import { Loader } from '@percent/cause-dashboard/common/components'
import { TitleAndBullets } from '@percent/cause-dashboard/common/components/TitleAndBullets'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import { MultiStepForm } from '@percent/cause-dashboard/common/components/multiStepForm/MultiStepForm'
import { FieldType, FormStep } from '@percent/cause-dashboard/common/components/multiStepForm/MultiStepForm.types'
import { MultiStepFormHeader } from '@percent/cause-dashboard/common/components/multiStepForm/multiStepFormHeader/MultiStepFormHeader'
import { useAuthDispatch, useMutation } from '@percent/cause-dashboard/common/hooks'
import { SignUpEventName } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/causeDashboardAnalytics.types'
import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import {
  SignUpReason,
  useSignUpAttribution
} from '@percent/cause-dashboard/common/hooks/useSignUpAttribution/useSignUpAttribution'
import { passwordRequirementRegExp } from '@percent/cause-dashboard/common/utility/validation'
import { config } from '@percent/cause-dashboard/config'
import {
  DETAIL_BULLET_POINTS_DEFAULT,
  DETAIL_BULLET_POINTS_STATUS_CHECK,
  detailTitle
} from '@percent/cause-dashboard/constants/emailVerificationData'
import { SET_AUTHORISED } from '@percent/cause-dashboard/context/auth'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { Button, ButtonText, Spacer, Text, Tooltip } from '@percent/lemonade'
import { emailRegex } from '@percent/utility'
import { FormikValues } from 'formik'
import { useTranslation } from 'react-i18next'
import { RoutePath } from '../../routes/Routes'
import { alpha3ToAlpha2, Alpha2Code } from 'i18n-iso-countries'

interface SignUpData {
  firstName: string
  lastName: string
  emailAddress: string
  password: string
  marketingConsent: boolean
  onboardingQuestionResponses: { questionId: string; answer: string }[]
  phone?: string
}

export function MultiStepSignUp() {
  const { t } = useTranslation()
  const { authDispatch } = useAuthDispatch()
  const { authService, claimService, causeService, accountService, causeUserService } = useServices()
  const { track, identify } = useCausesPortalAnalytics()
  const { signUpAttribution } = useSignUpAttribution()
  const history = useHistory()

  const handleRequestToJoinClick = () => {
    history.push(`${RoutePath.REQUEST_TO_JOIN}?id=${orgData?.id}&name=${orgData?.name}`)
  }

  const { search } = useLocation()
  const emailParam = new URLSearchParams(search).get('email') || ''
  const orgIdParam = new URLSearchParams(search).get('organization-id') || ''
  const validationSubmissionIdParam = new URLSearchParams(search).get('vs')
  const referringPartner = new URLSearchParams(search).get('partner-id') || undefined

  const [{ data: orgData, isLoading: isOrgLoading, error: isOrgError }] = useQuery(causeService.getOrganisation, {
    organisationId: orgIdParam || ''
  })

  const [
    {
      data: onboardingQuestionData,
      isLoading: isLoadingOnboardingQuestionData,
      error: errorLoadingOnboardingQuestionData
    }
  ] = useQuery(causeUserService.getInsightQuestionSet, 'onboarding')

  const formSteps = useMemo(() => {
    const userDetailsStepValidationSchema = Yup.object().shape({
      firstName: Yup.string()
        .required(t('form.isRequired', { name: 'First name' }))
        .min(3, t('form.minLength', { name: 'First name', length: 3 }))
        .max(255, t('form.maxLength', { name: 'First name', length: 255 })),
      lastName: Yup.string()
        .required(t('form.isRequired', { name: 'Last name' }))
        .min(3, t('form.minLength', { name: 'Last name', length: 3 }))
        .max(255, t('form.maxLength', { name: 'Last name', length: 255 })),
      emailAddress: Yup.string()
        .required(t('form.isRequired', { name: 'Email' }))
        .matches(emailRegex, t('errorMessage.validEmail')),
      phone: Yup.string().optional()
    })

    const passwordStepValidationSchema = Yup.object().shape({
      password: Yup.string()
        .min(8, t('form.minLength', { name: 'Password', length: 8 }))
        .max(64, t('form.maxLength', { name: 'First name', length: 64 }))
        .matches(passwordRequirementRegExp, t('errorMessage.passwordRequirement'))
        .required(t('form.isRequired', { name: 'Password' })),
      agreement: Yup.boolean().oneOf([true], t('errorMessage.acceptTerms')).required(t('errorMessage.acceptTerms')),
      marketingConsent: Yup.boolean().optional()
    })

    const getCountryCode = (country?: string): Alpha2Code => {
      if (!country) return 'US'
      return alpha3ToAlpha2(country) as Alpha2Code
    }

    const formSteps: FormStep[] = [
      {
        stepTitle: t('form.yourDetails'),
        stepSubtitle: t('form.signUpDescription', { organizationName: orgData?.name }),
        fields: [
          {
            name: 'firstName',
            label: t('form.firstName'),
            placeholder: t('form.firstNameDescription'),
            type: 'text'
          },
          {
            name: 'lastName',
            label: t('form.lastName'),
            placeholder: t('form.lastNameDescription'),
            type: 'text'
          },
          {
            name: 'emailAddress',
            label: t('form.emailAddress'),
            placeholder: t('form.enterEmailAddress'),
            type: 'text',
            defaultValue: emailParam,
            description: t('form.emailDescriptionSignUp')
          },
          {
            name: 'phone',
            label: t('form.signUp.phoneNumber'),
            placeholder: t('form.signUp.phoneNumber.placeholder'),
            type: 'phone',
            optional: true,
            defaultCountry: getCountryCode(orgData?.countryCode)
          }
        ],
        validationSchema: userDetailsStepValidationSchema,
        buttonText: t('button.next'),
        stepAction: async () => {
          await track(SignUpEventName.MULTI_STEP_FORM_STEP_1, {
            organisationName: orgData?.name
          })
        }
      },
      ...(onboardingQuestionData?.data?.data && !errorLoadingOnboardingQuestionData
        ? [
            {
              stepTitle: t('form.insights.title'),
              stepSubtitle: t('form.insights.subtitle'),
              fields: onboardingQuestionData?.data?.data.questions.map(question => ({
                name: question.id,
                label: question.label,
                placeholder: question.placeholder,
                type: question.type as FieldType,
                options: question.options,
                optional: true
              })),
              validationSchema: Yup.object().optional(),
              buttonText: t('button.next'),
              stepAction: async () => {
                await track(SignUpEventName.MULTI_STEP_FORM_STEP_2, {
                  organisationName: orgData?.name
                })
              }
            }
          ]
        : []),
      {
        stepTitle: t('form.oneThing'),
        stepSubtitle: t('form.passwordSubtitle'),
        fields: [
          {
            name: 'password',
            label: t('form.password'),
            placeholder: t('form.enterPassword'),
            type: 'password',
            description: t('form.passwordDescription')
          },
          {
            name: 'agreement',
            checkboxLabel: (
              <span className={styles.checkboxLabel}>
                {t('typography.iAcceptThe')}{' '}
                <a target="_blank" rel="noreferrer" href={config.urls.terms}>
                  {t('typography.termsOfService')}
                </a>{' '}
                {t('typography.and')}{' '}
                <a target="_blank" rel="noreferrer" href={config.urls.privacyAndPolicy}>
                  {t('typography.privacyPolicy')}
                </a>
              </span>
            ),
            type: 'checkbox'
          },
          {
            name: 'marketingConsent',
            checkboxLabel: (
              <span className={`${styles.checkboxLabel} ${styles.marketingConsent}`}>
                {t('typography.marketingConsentStart')}{' '}
                <a href={'#'}>
                  <Tooltip content={t('typography.marketingConsentDetail')}>
                    {t('typography.marketingConsentTooltipTrigger')}
                  </Tooltip>
                </a>{' '}
                {t('typography.marketingConsentEnd')}
              </span>
            ),
            type: 'checkbox'
          }
        ],
        validationSchema: passwordStepValidationSchema,
        buttonText: t('form.signUp')
      }
    ]

    return formSteps
  }, [t, orgData?.name, emailParam, onboardingQuestionData, track])

  const [{ errorMessage, isLoading }, { apiFunc: signUpUser }] = useMutation(
    async ({
      firstName,
      lastName,
      password,
      emailAddress: email,
      marketingConsent,
      onboardingQuestionResponses,
      phone: phoneNumber
    }: SignUpData) => {
      const signUpResponse = await authService.signUp({
        email,
        password,
        fullName: `${firstName} ${lastName}`,
        validationSubmissionSourceId: validationSubmissionIdParam || undefined,
        phoneNumber
      })

      await authService.login({ email, password })

      const { id } = signUpResponse.data.data

      await causeUserService.createCauseUser({
        accountId: id,
        signUpAttributionReason: signUpAttribution?.reason as SignUpReason,
        signUpAttributionPartnerId: signUpAttribution?.partnerId
      })

      const traits = {
        organisationId: orgData?.id,
        partnerId: referringPartner,
        email: email,
        organisation: orgData?.name,
        country: orgData?.countryCode
      }

      await identify({
        traits
      })

      authDispatch({
        type: SET_AUTHORISED,
        payload: {}
      })

      if (orgIdParam) {
        await claimService.postVSClaim({ organisationId: orgIdParam || '' })
      }

      await accountService.setMarketingConsent({
        consents: [
          { channel: 'email', consent: !!marketingConsent },
          { channel: 'phone', consent: !!marketingConsent }
        ]
      })

      if (onboardingQuestionData?.data?.data) {
        await causeUserService.saveInsightQuestionResponses({
          questionSetId: 'onboarding',
          questionResponses: onboardingQuestionResponses
        })
      }

      await track(SignUpEventName.SIGNUP_COMPLETED, {
        organisationName: orgData?.name
      })
    }
  )

  const onSubmit = async (values: FormikValues) => {
    let newOnboardingQuestionResponses: SignUpData['onboardingQuestionResponses'] = []
    if (onboardingQuestionData?.data?.data) {
      newOnboardingQuestionResponses = onboardingQuestionData?.data?.data.questions.map(question => ({
        questionId: question.id,
        answer: values[question.id]
      }))
    }

    signUpUser({ ...values, onboardingQuestionResponses: newOnboardingQuestionResponses } as SignUpData)
  }

  function handleLoginHere() {
    history.push(RoutePath.SIGNIN)
  }

  if (isOrgError) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { organizationNotFoundAlert: true }
        }}
      />
    )
  }

  const getLeftSectionLayout = () => {
    const isOrgClaimed = !!orgData?.claimedAt
    if (isLoading || isOrgLoading || isLoadingOnboardingQuestionData) {
      return <Loader />
    } else if (isOrgClaimed) {
      track(SignUpEventName.ORG_ALREADY_CLAIMED_ERROR, {
        organisationName: orgData?.name
      })
      return (
        <>
          <MultiStepFormHeader
            data-testId="error-header"
            title={t('form.yourDetails')}
            subtitle={t('form.requestToJoinDescription')}
          />
          <Spacer size={6} axis="vertical" />
          <Button variant="primary" stretch onPress={handleRequestToJoinClick}>
            {t('button.requestToJoin')}
          </Button>
        </>
      )
    } else {
      return (
        <>
          <MultiStepForm steps={formSteps} onSubmit={onSubmit} errorMessage={errorMessage} />
          <Spacer size={4} axis="vertical" />
          <Text size="small">
            <span className={styles.signedUp}>
              {t('typography.alreadySignedUp')}&nbsp;
              <ButtonText onPress={handleLoginHere}>{t('typography.loginHere')}</ButtonText>
            </span>
          </Text>
        </>
      )
    }
  }

  const bullets =
    signUpAttribution?.reason === SignUpReason.VERIFICATION_STATUS_CHECK
      ? DETAIL_BULLET_POINTS_STATUS_CHECK
      : DETAIL_BULLET_POINTS_DEFAULT

  return (
    <Grid container spacing={0} className={styles.container}>
      <Grid item sm={12} md={6} className={styles.signUpSection}>
        <Box className={styles.formWrapper}>{getLeftSectionLayout()}</Box>
        <Footer noTermsAndPrivacyPolicy />
      </Grid>
      <Grid className={styles.titleAndBullets} item sm={12} md={6}>
        <TitleAndBullets title={t(detailTitle)} bullets={bullets} />
      </Grid>
    </Grid>
  )
}
