import { Grid, Typography } from '@material-ui/core'
import { ReactComponent as Tick } from '@percent/cause-dashboard/common/assets/images/modal/tick.svg'
import { ReactComponent as InnerGreenCircle } from '@percent/cause-dashboard/common/assets/images/modal/inner-green-circle.svg'
import { ReactComponent as OuterGreenCircle } from '@percent/cause-dashboard/common/assets/images/modal/outer-green-circle.svg'
import { Loader } from '@percent/cause-dashboard/common/components/Loader'

import { AuthBackgroundImage } from '@percent/cause-dashboard/common/components'
import styles from './AgentVerify.module.scss'
import { AgentVerifyProps } from './AgentVerify.types'
import { LargeErrorView } from '@percent/cause-dashboard/common/components/LargeErrorView'
import { APIErrorHandler } from '@percent/cause-dashboard/common/library/APIErrorHandler'
import { useEffect } from 'react'
import { EmailFooter } from '@percent/cause-dashboard/common/components/emailFooter/EmailFooter'
import { AnimatedContainer } from '@percent/lemonade'
import { useTranslation, Trans } from 'react-i18next'

const reachOutUrl = 'poweredbypercent.zendesk.com'

export function AgentVerify({ isLoading, data, error }: AgentVerifyProps) {
  const { t: loco, i18n } = useTranslation('causeDashboardLoco')

  useEffect(() => {
    if (data?.language) {
      i18n.changeLanguage(data?.language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (error) return <LargeErrorView message={APIErrorHandler(error)} />

  return (
    <Grid
      spacing={0}
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={styles.container}
    >
      {isLoading ? (
        <Loader loaderStyle="authLoader" />
      ) : (
        <>
          <div className={styles.replyBox}>
            <div className={styles.replyContainer}>
              <AnimatedContainer>
                <div className={styles.circle}>
                  <OuterGreenCircle className={styles.outerGreenCircle} />
                  <InnerGreenCircle className={styles.innerGreenCircle} />
                  <Tick className={styles.tick} />
                </div>
              </AnimatedContainer>
              <Typography variant="h3">
                {loco(
                  `webscreen.ask_nonprofit_to_confirm_agent.${
                    data?.associationAlreadyRecorded ? 'headingReplySubmitted' : 'headingThanks'
                  }`
                )}
              </Typography>
              {data?.associationAlreadyRecorded ? (
                <Typography>
                  <Trans
                    ns="causeDashboardLoco"
                    i18nKey="webscreen.ask_nonprofit_to_confirm_agent.paragraphReplySubmittedDiscount"
                    values={{
                      agentFirstName: data?.firstName,
                      agentLastName: data?.lastName,
                      partner: data?.partnerName,
                      reachOutUrl
                    }}
                  >
                    {/* This is the template string that allows translation to different languages with link */}
                    You've already confirmed whether agentFirstName agentLastName is eligible for partner’s program. Use
                    the link below if you think there’s been a mistake
                    <a href="https://poweredbypercent.zendesk.com/" target="_blank" rel="noreferrer">
                      {reachOutUrl}
                    </a>
                  </Trans>
                </Typography>
              ) : (
                <Typography>
                  {loco('webscreen.ask_nonprofit_to_confirm_agent.paragraphThanksDiscount', {
                    agentFirstName: data?.firstName,
                    agentLastName: data?.lastName,
                    partner: data?.partnerName
                  })}
                </Typography>
              )}
            </div>
          </div>
          <AuthBackgroundImage />
          <EmailFooter />
        </>
      )}
    </Grid>
  )
}
