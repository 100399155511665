export enum MarketplaceProgrammeVisitedSource {
  Banner = 'Banner',
  Card = 'Card',
  Popup = 'Popup'
}

export enum ProgrammeViewEventKind {
  BannerShown = 'Banner shown',
  ModalShown = 'Calendly modal shown',
  PopupShown = 'Pop up shown',
  PopupDismissed = 'Pop up dismissed'
}

export enum PageViewSource {
  AppStore = 'App store',
  Profile = 'Profile page'
}

export enum ProgramGenericEventKind {
  ButtonClicked = 'Button clicked',
  Viewed = 'Viewed'
}

export const metadataKeys = ['originCategory', 'offerName', 'offerType', 'hasBuyNowOption'] as const

export type MarketingMetadataKey = typeof metadataKeys[number]

export type MarketingMetadataKeyStore = MarketingMetadataKey | 'buttonText'

export enum ProgramName {
  // Partner
  Airslate = 'AIRSLATE',
  Asana = 'ASANA',
  Atlassian = 'ATLASSIAN',
  Auth0 = 'AUTH0',
  Box = 'BOX',
  Breezy = 'BREEZY',
  Canva = 'CANVA',
  Docusign = 'DOCUSIGN',
  Eventbrite = 'EVENTBRITE',
  Fiverr = 'FIVERR',
  Freshworks = 'FRESHWORKS',
  Givebutter = 'GIVEBUTTER',
  Google = 'GOOGLE',
  Hootsuite = 'HOOTSUITE',
  HubSpot = 'HUBSPOT',
  LinkedInFundraise = 'LINKEDIN_FUNDRAISE',
  LinkedInHire = 'LINKEDIN_HIRE',
  NewRelic = 'NEW_RELIC',
  NordLayer = 'NORDLAYER',
  Okta = 'OKTA',
  OpenAI = 'OPENAI',
  PagerDuty = 'PAGERDUTY',
  PandaDoc = 'PANDADOC',
  QuickBooks = 'QUICKBOOKS',
  Sage = 'SAGE',
  Splunk = 'SPLUNK',
  Teamtailor = 'TEAMTAILOR',
  Toptal = 'TOPTAL',
  Twilio = 'TWILIO',
  Workable = 'WORKABLE',
  Xero = 'XERO',
  // Service
  CauseCommunity = 'CAUSE_COMMUNITY',
  DonationProcessing = 'DONATION_PROCESSING',
  VolunteerFinder = 'VOLUNTEER_FINDER',
  GoogleAdGrant = 'GOOGLE_AD_GRANT',
  GoogleWorkspace = 'GOOGLE_WORKSPACE',
  GrantSourcing = 'GRANT_SOURCING',
  HRPayroll = 'HR_PAYROLL',
  Marketing = 'MARKETING_SUPPORT',
  ManagedIT = 'MANAGED_IT',
  AccountingFinancial = 'ACCOUNTING_FINANCIAL_SERVICES',
  WebCMS = 'WEB_CMS_SUPPORT',
  Fundraising = 'FUNDRAISING_SUPPORT',
  Ping = 'PING'
}

export enum CategoryName {
  TopTools = 'TOP_TOOLS',
  ComingSoon = 'COMING_SOON',
  Fundraising = 'FUNDRAISING',
  Marketing = 'MARKETING',
  ProjectManagement = 'PROJECT_MANAGEMENT',
  LegalAccounting = 'LEGAL_ACCOUNTING',
  Security = 'SECURITY',
  Recruitment = 'RECRUITMENT',
  Productitivty = 'PRODUCTIVITY'
}

export type ProgramTracking = {
  name: ProgramName
  category: CategoryName
}
