import { MouseEvent, useState } from 'react'
import { Typography } from '@material-ui/core'

import { Widget as TypeformWidget } from '@typeform/embed-react'

import styles from './AppStoreCard.module.scss'
import { AppStoreCardProps } from './AppStoreCard.types'
import { ReactComponent as ArrowRight } from '@percent/cause-dashboard/common/assets/images/arrow-right.svg'
import { useTranslation } from 'react-i18next'
import { useAuthState } from '@percent/cause-dashboard/common/hooks'
import { useFeatureFlag } from '@percent/cause-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { selectAuthState } from '@percent/cause-dashboard/context/auth'
import { useHistory } from 'react-router-dom'
import { useMarketplaceAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useMarketplaceAnalytics'
import { MarketplaceProgrammeVisitedSource } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'
import { Button, Icon, Modal } from '@percent/lemonade'
import { ModalHeader } from '../../../../../../libs/shared/ui-lemonade/src/components/modal-header'
import { ModalTitle } from '../../../../../../libs/shared/ui-lemonade/src/components/modal-title'
import {
  PrimaryButtonLink,
  ProductOfferType,
  SecondaryButtonLink
} from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { PressEvent } from '@react-types/shared'
import AppStoreSuccessModal from './AppStoreSuccessModal/AppStoreSuccessModal'
import WaitlistModal from './WaitlistModal/WaitlistModal'
import CalendlyContainer from '../../../common/components/calendlyContainer/CalendlyContainer'
import { PERCENT_SERVICE_STRIPE_URL_PING } from '../data/appStoreUrls'

const DONATION_PROCESSING_OFFER = 'Donation processing'

export function AppStoreCard({
  name,
  description,
  category,
  type,
  logo,
  primaryButton,
  secondaryButton,
  selectedOfferName,
  selectOffer,
  offerIndex,
  tracking
}: Readonly<AppStoreCardProps>) {
  const { t } = useTranslation()
  const featureFlags = useFeatureFlag()
  const { authState } = useAuthState()
  const { claimAccepted } = selectAuthState(authState)
  const { organisation, claimOrganisation } = authState

  const { push } = useHistory()
  const { trackProgramVisited: track } = useMarketplaceAnalytics()
  const [openTypeformModal, setOpenTypeformModal] = useState<string | undefined>(undefined)
  const [hasSubmittedTypeform, setHasSubmittedTypeform] = useState(false)
  const [openAcknowledgeModal, setOpenAcknowledgeModal] = useState(false)

  const organisationId = organisation?.id ?? claimOrganisation?.id
  const useDonationProcessing = featureFlags['donationForm'] && claimAccepted
  const hasBuyNowButton =
    primaryButton.linkText === 'productOffers.buyNow' || secondaryButton?.linkText === 'productOffers.buyNow'
  const linksToAddParamsTo = [PERCENT_SERVICE_STRIPE_URL_PING]

  const getPrimaryLink = (button: PrimaryButtonLink) => {
    return button.link ?? ''
  }

  const getSecondaryLink = (button: SecondaryButtonLink) => {
    return button?.link ?? ''
  }

  const getTypeformIFrame = (primaryButton: PrimaryButtonLink, secondaryButton: SecondaryButtonLink) =>
    primaryButton.linkType === 'typeform'
      ? primaryButton.typeformId
      : secondaryButton?.linkType === 'typeform'
      ? secondaryButton.typeformId
      : undefined

  const getCalendlyAvailability = (primaryButton: PrimaryButtonLink, secondaryButton: SecondaryButtonLink) =>
    primaryButton.linkType === 'calendly' || secondaryButton?.linkType === 'calendly'

  const primaryLink = getPrimaryLink(primaryButton)
  const secondaryLink = getSecondaryLink(secondaryButton)
  const isCalendlyCallAvailable = getCalendlyAvailability(primaryButton, secondaryButton)
  const typeformIFrame = getTypeformIFrame(primaryButton, secondaryButton)
  const isInternalLinkAvailable = primaryButton.linkType === 'internal'

  const trackProgramVisited = async (
    link: string,
    linkText: string,
    source: MarketplaceProgrammeVisitedSource = MarketplaceProgrammeVisitedSource.Card
  ) =>
    track({
      source,
      targetUrl: link,
      programmeOfferName: name,
      productCategory: category,
      productIndex: offerIndex,
      programTracking: tracking,
      metadata: {
        hasBuyNowOption: hasBuyNowButton,
        offerType: type,
        offerName: name,
        originCategory: category,
        buttonText: linkText
      }
    })

  const handleOnClick = async (event: PressEvent, link: string, linkText: string, button: SecondaryButtonLink) => {
    await trackProgramVisited(link, linkText, MarketplaceProgrammeVisitedSource.Card)

    if (typeformIFrame) {
      setOpenTypeformModal(typeformIFrame)
    } else if (isInternalLinkAvailable) {
      setOpenAcknowledgeModal(true)
    } else if (button?.linkType === 'external') {
      if (linksToAddParamsTo.includes(link) && organisationId) {
        const url = new URL(link)
        url.searchParams.set('client_reference_id', organisationId)
        link = url.toString()
      }
      window.open(link, '_blank')
    } else if (isCalendlyCallAvailable) {
      selectOffer(name)
    }
  }

  const handleDonationClick = async (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    await trackProgramVisited(primaryLink, t(primaryButton.linkText), MarketplaceProgrammeVisitedSource.Card)
    push('/donation-form')
  }

  const handleCloseTypeformModal = () => {
    setOpenTypeformModal(undefined)
    setHasSubmittedTypeform(false)
  }

  const handleCloseAcknowledgeModal = () => {
    setOpenAcknowledgeModal(false)
  }

  return (
    <>
      <div className={styles.appStoreCardContainer}>
        <div className={styles.cardDetails}>
          <div className={styles.cardLogoWrapper}>
            {type === ProductOfferType.PercentService && (
              <div style={{ position: 'relative' }}>
                <Icon name={'percent'} />
                {logo && (
                  <div className={styles.subImage}>
                    <img
                      src={logo}
                      alt={name}
                      style={{
                        width: '100%',
                        height: '100%'
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {logo && type !== ProductOfferType.PercentService && (
              <div>
                <img src={logo} alt={name} className={styles.image} />
              </div>
            )}
          </div>

          <Typography
            variant="h6"
            color="secondary"
            style={{
              lineHeight: '1.5rem'
            }}
          >
            {name}
          </Typography>
          <Typography className={styles.description}>{description}</Typography>
        </div>
        {name === DONATION_PROCESSING_OFFER && useDonationProcessing ? (
          <div>
            <button onClick={handleDonationClick} className={styles.popup}>
              <Typography className={styles.pinkLink}>
                {t('typography.configure')} <ArrowRight className={styles.arrowRight} />
              </Typography>
            </button>
          </div>
        ) : (
          <div className={styles.cardCta}>
            {secondaryButton ? (
              <>
                <Button
                  onPress={async event => {
                    await handleOnClick(event, secondaryLink, t(secondaryButton.linkText), secondaryButton)
                  }}
                  data-testid={'offer_' + name}
                  variant={'secondary'}
                  size={'small'}
                  stretch
                >
                  <Typography className={styles.pinkLink}>{t(secondaryButton.linkText)}</Typography>
                </Button>
                <Button
                  onPress={async event => {
                    await handleOnClick(event, primaryLink, t(primaryButton.linkText), primaryButton)
                  }}
                  data-testid={'offer_express_' + name}
                  size={'medium'}
                >
                  <Typography className={styles.whiteLink}>
                    {t(primaryButton.linkText)}
                    <ArrowRight className={styles.arrowRightWhite} />
                  </Typography>
                </Button>
              </>
            ) : (
              <Button
                onPress={async event => {
                  await handleOnClick(event, primaryLink, t(primaryButton.linkText), primaryButton)
                }}
                data-testid={'offer_' + name}
                variant={'secondary'}
                size={'medium'}
                stretch
              >
                <Typography className={styles.pinkLink}>
                  {t(primaryButton.linkText)}
                  <ArrowRight className={styles.arrowRight} />
                </Typography>
              </Button>
            )}
          </div>
        )}
      </div>
      {hasSubmittedTypeform && (
        <AppStoreSuccessModal
          trackingData={{
            offerName: name,
            categoryName: category,
            hasBuyNowOption: hasBuyNowButton
          }}
          offerType={type ?? ProductOfferType.PercentService}
          open={hasSubmittedTypeform}
          onClose={handleCloseTypeformModal}
        />
      )}

      {isCalendlyCallAvailable && name === selectedOfferName && (
        <CalendlyContainer
          url={primaryButton.linkType === 'calendly' ? primaryLink : secondaryLink}
          selectItem={selectOffer}
          tracking={{ source: MarketplaceProgrammeVisitedSource.Card, programmeName: name }}
        />
      )}

      {openTypeformModal && !hasSubmittedTypeform && (
        <Modal width={800} open={Boolean(openTypeformModal)} onClose={handleCloseTypeformModal}>
          <ModalHeader>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: 1,
                padding: 4,
                width: '100%'
              }}
            >
              <ModalTitle>{name}</ModalTitle>
              <button
                style={{
                  padding: 0,
                  border: 'none',
                  background: 'none',
                  marginLeft: 'auto'
                }}
                onClick={handleCloseTypeformModal}
                data-testid={'typeform-dialog'}
              >
                <Icon name="close" size={4} color="gray600" />
              </button>
            </div>
          </ModalHeader>
          <TypeformWidget
            id={openTypeformModal}
            data-testid={'typeform-widget'}
            hidden={{
              organisation_id: authState.claimOrganisation?.id ?? 'unknown',
              email: authState.user?.email ?? 'unknown',
              account_id: authState.user?.id ?? 'unknown'
            }}
            className={styles.typeformWidget}
            onSubmit={() => setHasSubmittedTypeform(true)}
          />
        </Modal>
      )}
      {openAcknowledgeModal && <WaitlistModal open={openAcknowledgeModal} onClose={handleCloseAcknowledgeModal} />}
    </>
  )
}
