import { Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'

import styles from './ProductOffersPage.module.scss'

import { categorizedProducts } from '@percent/cause-dashboard/app/productOffers/data/categories'
import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { MarketplaceProgrammeVisitedSource } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'
import { useCausesPortalAnalytics } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/useCausesDashboardAnalytics'
import { selectAuthState } from '@percent/cause-dashboard/context/auth'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useCalendlyEventListener } from 'react-calendly'
import { MarketplaceBanner } from './MarketPlaceBanner/MarketplaceBanner'
import { AppStoreCard } from './AppStoreCard/AppStoreCard'
import { ProductOffer } from './data/data.types'
import { AuthStateType } from '@percent/cause-dashboard/context/auth/authContext/AuthContext.types'

const COUNTRIES_TO_ALLOW_BOOK_CALL_FLOW = ['USA', 'CAN', 'AUS', 'FRA', 'GBR']

const shouldDisplayOffer = (productOffer: ProductOffer, authState: AuthStateType) => {
  const { claimAccepted } = selectAuthState(authState)
  const acceptCallsFromMyCountry = COUNTRIES_TO_ALLOW_BOOK_CALL_FLOW.includes(authState.organisation?.countryCode || '')

  const canBookCall = claimAccepted && acceptCallsFromMyCountry
  const hasBookCallCTA =
    productOffer.primaryButton.linkType === 'calendly' || productOffer.secondaryButton?.linkType === 'calendly'
  return productOffer.globalOverride || canBookCall || !hasBookCallCTA
}

export function ProductOffersPage() {
  const { page } = useCausesPortalAnalytics()
  const { calendlyService } = useServices()
  const { authState } = useAuthState()
  const [{ isLoading }, { apiFunc: saveMeetingLink }] = useMutation(calendlyService.saveMeetingLink)
  const [selectedOfferName, setSelectedOfferName] = useState<string | undefined>(undefined)

  useEffect(() => {
    page('Marketplace')
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [doesCountryAcceptCalls, setDoesCountryAcceptCalls] = useState(false)

  const myCountry = authState.organisation?.countryCode

  useEffect(() => {
    if (typeof myCountry === 'string' && COUNTRIES_TO_ALLOW_BOOK_CALL_FLOW.includes(myCountry)) {
      setDoesCountryAcceptCalls(true)
    }
  }, [myCountry])

  // Has to be put in parent not in PartnerCard
  // Listener is global, and was triggering for each card causing multiple http calls
  useCalendlyEventListener({
    onEventScheduled: event => {
      const uri = event.data.payload.event.uri
      if (uri && !isLoading) {
        saveMeetingLink({
          accountId: authState.user?.id,
          organisationId: authState.user?.organisationId ?? '',
          email: authState.user?.email,
          source: selectedOfferName ?? 'app-store-offer',
          meetingLink: uri
        })
      }
      setSelectedOfferName(undefined)
    }
  })

  return (
    <div className={styles.productOffersPageContainer} id="marketplace-container">
      <MarketplaceBanner
        tracking={{
          source: MarketplaceProgrammeVisitedSource.Banner,
          programmeName: 'LinkedIn'
        }}
      />

      <div className={styles.productCategoriesWrapper}>
        {categorizedProducts.map(category => (
          <section key={category.id} title={category.name} className={styles.productOffersWrapper}>
            <div className={styles.sectionHeader}>
              {category.featured && <div className={styles.accentText}>FEATURED</div>}
              <div className={styles.sectionTitle}>{category.name}</div>
              <div className={styles.sectionDescription}>{category.description}</div>
            </div>
            <Grid container spacing={2} className={styles.productOffersPageGrid}>
              {category.offers.map((productOffer, offerIndex) => {
                return (
                  shouldDisplayOffer(productOffer, authState) && (
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={2} key={productOffer.name}>
                      <AppStoreCard
                        {...productOffer}
                        category={category.name}
                        selectOffer={setSelectedOfferName}
                        selectedOfferName={selectedOfferName}
                        offerIndex={offerIndex + 1}
                        tracking={{
                          name: productOffer.tracking,
                          category: category.tracking
                        }}
                      />
                    </Grid>
                  )
                )
              })}
            </Grid>
          </section>
        ))}
      </div>
    </div>
  )
}
